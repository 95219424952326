<main>
	<img class="logo" src="../logo.svg" alt="Brigadier logo" />
	<p class="text">Coming soon</p>
</main>

<style>
	:global(body) {
		height: 100%;
		margin: 0;
		background-color: #000;
		background-image: url('../brigadier-final-min.png');
		background-position: center;
  		background-repeat: no-repeat;
  		background-size: cover;
	}

	main {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		align-items:center;
	}

	.logo {
		width: 82rem;
	}

	.text {
		color: #fff;
		text-transform: uppercase;
		font-size: 2em;
		font-weight: 100;
		letter-spacing: 2rem;
		text-indent: 2rem;
		text-align: center;
	}

	@media (max-width: 1560px) {
		.logo {
			width: 72rem;
		}
	}

	@media (max-width: 1440px) {
		.logo {
			width: 62rem;
		}
	}

	@media (max-width: 1280px) {
		.logo {
			width: 52rem;
		}
	}

	@media (max-width: 968px) {
		.logo {
			width: 42rem;
		}
	}

	@media (max-width: 728px) {
		.logo {
			width: 36rem;
		}

		.text {
			font-size: 1.6em;
			font-weight: 400;
		}
	}

	@media (max-width: 568px) {
		.logo {
			width: 24rem;
		}

		.text {
			font-size: 0.8em;
			font-weight: 400;
			letter-spacing: 1.4rem;
			text-indent: 1.4rem;
		}
	}

	@media (max-width: 428px) {
		.logo {
			width: 16rem;
		}

		.text {
			font-size: 0.6em;
			font-weight: 400;
			letter-spacing: 1.2rem;
			text-indent: 1.2rem;
		}
	}
</style>